import { Component } from 'react'
import injectSheet from 'react-jss'
import { TweenMax } from 'gsap'
import DelayLink from '@/components/DelayLink'
import { CONFIG } from '@/constants'
import style from './style'

class Logo extends Component {
  /*------------------------------
  Component Did Mount
  ------------------------------*/
  componentDidMount() {
    const { logo, svg } = this.props.classes
    this.$ = {
      root: this.node,
      logo: this.node.getElementsByClassName(logo),
      svg: this.node.getElementsByClassName(svg),
    }
    this.menuIsOpen = (this.props.navOpen) ? 'navOpen' : ''
  }

  /*------------------------------
  Component Did Update
  ------------------------------*/
  componentDidUpdate(prevProps) {
    const { navOpen, theme } = this.props
    if (navOpen !== prevProps.navOpen) {
      this.menuIsOpen = (navOpen) ? 'navOpen' : ''
      if (navOpen) {
        TweenMax.to(this.$.svg, 0.2, {
          fill: theme.colors[3],
          delay: 0.4,
        })
      } else {
        TweenMax.to(this.$.svg, 0.2, {
          fill: theme.colors[2],
          delay: 0.7,
        })
      }
    }
  }

  /*------------------------------
  Render
  ------------------------------*/
  render() {
    this.path = ''
    if (window.pathSite) this.path = window.pathSite
    const { classes, className } = this.props
    return (
      <div
        className={`${classes.root} ${className}`}
        ref={(node) => { this.node = node }}
      >
        {this.props.preview ? (
          <div
            className={`${classes.logo} ${this.menuIsOpen}`}
          >
            <svg
              className={classes.svg}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 76 15"
              width="76"
              height="15"
              preserveAspectRatio="xMidYMin slice"
            >
              <path d="M0,9.1l0-3.2h3.9l0,3.2c0,1.1,0.8,1.9,1.9,1.9h11.6V15H5.8C2.6,15,0,12.4,0,9.1 M76,5.9l0,9.1h-3.9l0-9.1 c0-1.1-0.8-1.9-1.9-1.9h-18V0h18C73.4,0,76,2.6,76,5.9 M62.1,5.9h3.9V15h-3.9V5.9z M52.2,5.9h3.9V15h-3.9V5.9z M21.8,5.9h3.9V15 h-3.9V5.9z M21.8,0h3.9v3.9h-3.9V0z M44.7,5.9l4.2,9.1h-4.3l-4.2-9.1H44.7z M34.1,3.9L35.9,0h6l1.8,3.9H34.1z M28.9,15l4.2-9.1h4.3 L33.3,15H28.9z M0.3,3.9C1.1,1.7,3.3,0,5.8,0h11.6v3.9H0.3z" />
            </svg>
          </div>
        ) : (
          <DelayLink
            to={`/${this.path}`}
            delay={CONFIG.LINK_DELAY}
            className={`${classes.logo} ${this.menuIsOpen}`}
          >
            <svg
              className={classes.svg}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 76 15"
              width="76"
              height="15"
              preserveAspectRatio="xMidYMin slice"
            >
              <path d="M0,9.1l0-3.2h3.9l0,3.2c0,1.1,0.8,1.9,1.9,1.9h11.6V15H5.8C2.6,15,0,12.4,0,9.1 M76,5.9l0,9.1h-3.9l0-9.1 c0-1.1-0.8-1.9-1.9-1.9h-18V0h18C73.4,0,76,2.6,76,5.9 M62.1,5.9h3.9V15h-3.9V5.9z M52.2,5.9h3.9V15h-3.9V5.9z M21.8,5.9h3.9V15 h-3.9V5.9z M21.8,0h3.9v3.9h-3.9V0z M44.7,5.9l4.2,9.1h-4.3l-4.2-9.1H44.7z M34.1,3.9L35.9,0h6l1.8,3.9H34.1z M28.9,15l4.2-9.1h4.3 L33.3,15H28.9z M0.3,3.9C1.1,1.7,3.3,0,5.8,0h11.6v3.9H0.3z" />
            </svg>
          </DelayLink>
        )}
      </div>
    )
  }
}

export default injectSheet(style)(Logo)
