function Fieldset({
  children,
  title,
}) {
  return (
    <fieldset style={{ appearance: 'none', padding: 0, margin: 0, border: 'none' }}>
      <legend className="visuallyHidden" style={{ appearance: 'none' }}>
        {title}
      </legend>
      {children}
    </fieldset>
  )
}
export default Fieldset
