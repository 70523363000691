const style = (theme) => {
  return {
    formRoot: {
      marginTop: 0,
      marginBottom: 'calc(0.5 * var(--spacer-xxl))',
      display: 'flex',
      flexDirection: 'column',
      '& label[for="name-surname"]': {
        display: 'none',
      },
      '& input[name="name-surname"]': {
        display: 'none',
      },
    },
    formDisabled: {
      '& *': {
        pointerEvents: 'none !important',
      },
      pointerEvents: 'none',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: 15,
    },
    result: {
      marginTop: 20,
      padding: '14px 20px',
      color: theme.colors[1],
      display: 'none',
      background: 'var(--background-color)',
      userSelect: 'none',
      '& p': {
        margin: 0,
        textAlign: 'center',
        fontFamily: theme.fonts[0],
      },
    },
    visibility: {
      display: 'block',
    },
    submitContainer: {
      marginTop: 30,
    },
    submit: {
      borderRadius: 20,
      background: theme.colors[2],
      color: theme.colors[1],
      padding: '8px 20px',
      margin: '0 auto',
      pointerEvents: 'all',
      fontFamily: theme.fonts[0],
      fontSize: 18,
      cursor: 'pointer',

      [theme.mq.sm]: {
        width: '100%',
        marginBottom: 20,
      },

      '&:disabled': {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  }
}

export default style
