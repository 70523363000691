import { create } from 'zustand'
import { produce } from 'immer'
import { devtools } from 'zustand/middleware'

const store = (set) => ({
  currentProject: null,
  projectName: '',
  projectImage: '',
  setCurrentProject: (project, name, image) => set(
    produce((st) => {
      st.currentProject = project
      st.projectName = name
      st.projectImage = image
    }),
    false,
    'CONFIGURATOR/SET_PROJECT',
  ),
  resetProject: () => set(
    produce((st) => {
      st.currentProject = null
      st.projectName = ''
      st.projectImage = ''
    }),
    false,
    'CONFIGURATOR/RESET_PROJECT',
  ),
})

const useConfiguratorStore = create(devtools(store, { name: 'Configurator Store' }))

export default useConfiguratorStore
