import injectSheet from 'react-jss'
import clsx from 'clsx'
import style from './style'

function Error({
  classes,
  id,
  className,
  message,
  visibility,
  children,
}) {
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [className]: className,
      })}
    >
      {children}
      <p
        id={id}
        role="alert"
        className={clsx({
          [classes.errorMessage]: true,
          visuallyHidden: !visibility,
        })}
      >
        {message}
      </p>
    </div>
  )
}

export default injectSheet(style)(Error)
