import { useRef, useState, useMemo, memo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { InputType } from '@/utils/form/types'
import Input from '@/components/__form/Input'
import MultipleSelect from '@/components/__form/MultipleSelect'
import Textarea from '@/components/__form/Textarea'
import Checkbox from '@/components/__form/Checkbox'

function FormField({ field, formik, updateForm }) {
  const isValidInputType = InputType.includes(field.type)
  const { currentLanguage } = useSelector((state) => ({
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  const [isAriaCurrent, setIsAriaCurrent] = useState(false)
  const $root = useRef(null)

  const onKeyDown = (e) => {
    const actions = {
      ArrowRight: null,
      ArrowLeft: null,
    }

    const action = actions[e.key]
    if (!action) return

    if (!e.shiftKey) {
      action({ wrap: true })
    }
  }

  const setAriaCurrent = () => {
    if (!$root.current) return
    setIsAriaCurrent(true)
  }

  const removeAriaCurrent = () => {
    if (!$root.current) return
    setIsAriaCurrent(false)
  }

  const commonProps = useMemo(() => {
    let { label, label2, options, span, placeholder, asyncdata, axiosdata } = field

    if (typeof field.label === 'object') {
      label = label?.[currentLanguage]
    }

    if (typeof field.label2 === 'object') {
      label2 = label2?.[currentLanguage]
    }

    if (!Array.isArray(options) && typeof options !== 'string') {
      options = options?.[currentLanguage]
    }

    if (typeof field.span === 'object') {
      span = span?.[currentLanguage]
    }

    if (typeof field.placeholder === 'object') {
      placeholder = placeholder?.[currentLanguage]
    }

    if (typeof field.asyncdata === 'object') {
      asyncdata = asyncdata?.[currentLanguage]
    }

    if (typeof field.axiosdata === 'object') {
      axiosdata = axiosdata?.[currentLanguage]
    }

    return {
      className: field.cssClass,
      ref: $root || null,
      label: `${label} ${field.isRequired ? '*' : ''}`,
      label2: `${label2} ${field.isRequired ? '*' : ''}`,
      checkboxLabel: field.checkboxLabel || '',
      disabled: formik.isSubmitting,
      name: `input_${field.id}`,
      onFocus: setAriaCurrent,
      onChange: formik.handleChange,
      onBlur: (e) => {
        formik.handleBlur(e)
        removeAriaCurrent()
      },
      onKeyDown,
      placeholder: placeholder || label,
      isRequired: field.isRequired,
      type: field.type,
      value: formik.values[`input_${field.id}`],
      error: formik.errors[`input_${field.id}`],
      touched: formik.touched[`input_${field.id}`] || false,
      hasLabel: true,
      spellCheck: 'false',
      autoComplete: 'off',
      submitCount: formik.submitCount,
      setFieldValue: formik.setFieldValue,
      'aria-current': isAriaCurrent,
      options: field.choices || options,
      optionsData: field.data?.result || [],
      visibility: field.visibility,
      visibilitylogic: field.visibilityLogic,
      multiple: field.multiple,
      map: field.map,
      values: formik.values,
      initialvalues: formik.initialValues,
      dynamic: field.dynamic,
      dependency: field.dependency || null,
      asyncdata,
      axiosdata,
      span,
    }
  }, [field, formik, isAriaCurrent])

  if (field.type === 'textarea') {
    return (
      <Textarea
        {...commonProps}
        updateForm={updateForm}
        maxLength={field.maxLength}
      />
    )
  }

  if (field.type === 'consent') {
    return <Checkbox {...commonProps} updateForm={updateForm} />
  }

  if (field.type === 'select') {
    const props = { ...commonProps }
    props.isClearable = field.isClearable || false

    props.onChange = (selectedOption) => {
      const value = Array.isArray(selectedOption)
        ? selectedOption.map((o) => o.value).join(',')
        : selectedOption?.value || ''
      if (value) {
        formik.setFieldValue(props.name, value)
      }
    }
    props.value = props.value || ''

    return <MultipleSelect {...props} updateForm={updateForm} />
  }

  return isValidInputType ? (
    <Input {...commonProps} updateForm={updateForm} />
  ) : null
}

export default memo(FormField)
