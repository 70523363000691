import mq from '@/styles/mq'
import { typography, fonts } from '@/styles/typography'
import { color } from '@/styles/colors'
import easings from '@/styles/easings'

export const margin = {
  default: '30px',
  sm: '15px',
}

export const gutter = {
  default: '15px',
  sm: '15px',
}

export const grid = {
  '--cols': 12,
  '--margin': margin.default,
  '--gutter': gutter.default,
  display: 'flex',
  flexWrap: 'wrap',
  width: 'calc(100% - calc((var(--margin) - (var(--gutter) * 0.5)) * 2))',
  margin: '0 auto',
  [mq.sm]: {
    '--cols': 4,
    '--margin': margin.sm,
    '--gutter': gutter.sm,
  },
}

const cols = Array(12)
  .fill()
  .reduce((acc, val, i) => {
    acc[`.form-col-${i + 1}`] = {
      ...grid.col,
      '--col': i + 1,
      marginBottom: gutter.md,
      [mq.sm]: {
        '--col': 12,
      },
    }
    return acc
  }, {})

const widths = [3, 4, 6, 8, 12].reduce((acc, val) => {
  acc[`.form-item-${val}`] = {
    gridColumnEnd: `span ${val}`,
    transition: `opacity 0.5s ${easings['circ.out']}`,
    [mq.sm]: {
      gridColumnEnd: 'span 12',
    },
  }
  return acc
}, {})

export const form = {
  /*------------------------------
  Cols
  ------------------------------*/
  ...cols,

  /*------------------------------
  Grid Based Form Items
  ------------------------------*/
  ...widths,

  /*------------------------------
  Title
  ------------------------------*/
  '.form-title': {
    ...typography.h4,
    color: 'var(--color)',
    marginBottom: '0.5rem',
    cursor: 'default',
    userSelect: 'none',
  },

  /*------------------------------
  Title 2
  ------------------------------*/
  '.form-title-2': {
    ...typography.h5,
    color: 'var(--color)',
    marginBottom: '0.5rem',
    cursor: 'default',
    userSelect: 'none',
  },

  /*------------------------------
  Label
  ------------------------------*/
  '.form-label': {
    color: 'var(--color)',
    marginBottom: '0.5rem',
    display: 'inline-block',
    fontSize: typography.label,
    fontFamily: fonts[0],
    letterSpacing: '0.5px',
    cursor: 'default',
    userSelect: 'none',
    '& + .form-check-input': {
      marginTop: '0.5rem',
    },
  },

  /*------------------------------
  Span
  ------------------------------*/
  '.form-span': {
    display: 'block',
    color: 'var(--color-neutral-60)',
    marginBottom: 12,
    fontSize: 13,
    letterSpacing: '0.5px',
    fontFamily: fonts[0],
    '& a': {
      textDecoration: 'underline',
    },
    '& .ico-info': {
      width: 16,
      height: 16,
      verticalAlign: 'bottom',
      marginRight: 6,
      fill: 'var(--color-shortstay-variant-medium)',
    },
  },

  /*------------------------------
  Text
  ------------------------------*/
  '.form-text': {
    marginTop: '0.25rem',
    fontSize: '0.815em',
    letterSpacing: '0.5px',
    color: 'var(--color-neutral-80)',
  },
  '.form-error': {
    marginTop: '0.25rem',
    fontSize: '0.815em',
    letterSpacing: '0.5px',
    color: color.error,
  },

  /*------------------------------
  Controls
  ------------------------------*/
  '.form-control': {
    display: 'block',
    width: '100%',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: 'var(--color)',
    backgroundColor: 'transparent',
    backgroundClip: 'padding-box',
    border: '1px solid #ccc',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    fontFamily: fonts[0],
    '&::placeholder': {
      ...typography.paragraph,
      textTransform: 'none',
      color: 'var(--color-neutral-60)',
      fontWeight: '400',
    },
    '&:focus': {
      color: 'var(--color)',
      backgroundColor: '#fff',
      borderColor: 'var(--color)',
      outline: '0',
      boxShadow: '0 0 0 2px #d3dbf1',
    },
    '&:disabled': {
      backgroundColor: '#fafafa',
      cursor: 'not-allowed',
    },
    '&.invalid': {
      borderColor: color.error,
      paddingRight: 'calc(1.5em + 0.75rem)',
      backgroundImage:
        "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
      backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
      '&:focus': {
        color: color.error,
        borderColor: color.error,
        outline: '0',
        boxShadow: '0 0 0 2px rgb(220 53 69 / 25%)',
      },
    },
    '&.disabled': {
      pointerEvents: 'none',
      opacity: 0.5,
    },
    '&[type="date"]': {
      height: 38,
    },
    '&[type="textarea"]': {
      resize: 'vertical',
    },
    '&[type="file"]': {
      padding: 0,
    },
  },

  /*------------------------------
  Disabled
  ------------------------------*/
  '.form-disabled': {
    cursor: 'not-allowed',
    '& *': {
      pointerEvents: 'none!important',
    },
  },

  /*------------------------------
  Select
  ------------------------------*/
  '.form-select': {
    display: 'block',
    width: '100%',
    padding: '0.375rem 2.25rem 0.375rem 0.75rem',
    MozPaddingStart: 'calc(0.75rem - 3px)',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: 'var(--color)',
    backgroundColor: '#fff',
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e\")",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0.75rem center',
    backgroundSize: '16px 12px',
    border: '2px solid var(--color-neutral-10)',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    letterSpacing: '0.5px',
    '&:focus': {
      borderColor: 'var(--color)',
      outline: '0',
      boxShadow: '0 0 0 2px #d3dbf1',
    },
    '&:disabled': {
      backgroundColor: '#fafafa',
      cursor: 'not-allowed',
    },
    '&.invalid': {
      borderColor: color.error,
      '&:focus': {
        color: color.error,
        borderColor: color.error,
        outline: '0',
        boxShadow: '0 0 0 2px rgb(220 53 69 / 25%)',
      },
    },
    '& .react-select__control': {
      borderWidth: 2,
    },
  },

  /*------------------------------
  Button
  ------------------------------*/
  '.form-btn': {
    // width: '100%',
    // display: 'block',
    '& span': {
      // width: '100%',
      // display: 'flex',
      // justifyContent: 'space-between',
    },
  },

  /*------------------------------
  Checkbox / Radio
  ------------------------------*/
  '.form-check-input': {
    position: 'relative',
    zIndex: 1,
    fontFamily: fonts[0],

    '& input[type="radio"], & input[type="checkbox"]': {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0.001,
    },
    '& input[type="radio"] + span, & input[type="checkbox"] + span': {
      position: 'relative',
      zIndex: 1,
      paddingLeft: 40,
      fontSize: '0.75rem',
      fontWeight: 600,
      fontFamily: fonts[0],
      textTransform: 'uppercase',
      lineHeight: '170%',
      letterSpacing: '0.15em',
      textAlign: 'left',
      cursor: 'default',
      '& a': {
        color: 'currentColor',
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'inline-block',
        margin: '0 3px',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        cursor: 'pointer',
        zIndex: 1,
        width: 15,
        height: 15,
        marginRight: gutter.sm,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)',
        transition:
          'background-color .15s ease-in-out, box-shadow .15s ease-in-out',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 3,
        top: -1,
        cursor: 'pointer',
        zIndex: 1,
        width: 9,
        height: 9,
        marginRight: gutter.sm,
        borderRadius: '50%',
        backgroundColor: 'black',
        opacity: 0,
        transition: 'opacity .15s ease-in-out',
      },
    },
    '& input[type="radio"]:checked + span, & input[type="checkbox"]:checked + span':
      {
        '&:after': {
          opacity: 1,
        },
      },
    '&.invalid': {
      '& input[type="radio"] + span, & input[type="checkbox"] + span': {
        '&:before': {
          boxShadow: `inset 0px 0px 4px ${color.error}`,
        },
      },
    },

    /* Gruppo di input check  */
    '& > label + div, & > label + div + div': {
      '& .form-check-input input[type="radio"]+span, & .form-check-input input[type="checkbox"]+span':
        {
          fontSize: 16,
          fontFamily: fonts[0],
          textTransform: 'none',
          color: 'var(--color-neutral-60)',
          letterSpacing: '0.5px',
          fontWeight: '400',
        },
    },
  },

  /*------------------------------
  Inverse
  ------------------------------*/
  '.inverse': {
    '& .form-control': {
      color: 'var(--background)',
      '&:focus': {
        color: 'var(--background)',
        borderColor: 'var(--background)',
      },
    },
    '& .form-check-input': {
      '& input[type="radio"] + span, & input[type="checkbox"] + span': {
        '&:before': {
          boxShadow: 'inset 0px 0px 4px rgba(255, 255, 255, 0.9)',
        },
        '&:after': {
          backgroundColor: 'white',
        },
      },
    },
    '& .form-select': {
      color: 'var(--background)',
      '&:focus': {
        color: 'var(--background)',
      },
    },
    '& [class$="-Input"]': {
      '& input': {
        color: 'var(--background)!important',
      },
    },
    '& [class$="-singleValue"]': {
      color: 'var(--background)!important',
    },
  },

  '& .react-select__menu-portal': {
    zIndex: '9999!important',
  },
  '& .react-select__menu': {
    marginTop: 0,
  },
  '& .react-select__single-value': {
    fontFamily: fonts[0],
  },
  '& .col-full': {
    gridColumn: '1 / -1',
  },
  '& .col-margin-top': {
    marginTop: 30,
  },
}
