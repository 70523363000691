import { useEffect, useState, useRef, forwardRef } from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import Error from '@/components/__form/Error'
import Label from '@/components/__form/Label'
import { omit } from '@/utils/object'
import style from '../BaseForm/style'

const Textarea = forwardRef(
  (
    {
      className,
      disabled,
      label,
      name,
      error,
      touched,
      hasLabel,
      submitCount,
      value,
      onBlur,
      onFocus,
      visibility,
      visibilitylogic,
      values,
      span,
      updateForm,
      ...props
    },
    ref,
  ) => {
    const accessibleErrorName = useRef(`${name}-error`)
    const [isFocused, setFocused] = useState(false)
    const [isError, setError] = useState(false)
    const [fieldVisibility, setFieldVisibility] = useState(visibility === null)

    const handleBlur = (e) => {
      onBlur(e)
      setFocused(false)
    }

    const handleFocus = (e) => {
      onFocus(e)
      setFocused(true)
    }

    useEffect(() => {
      setError(touched && error && (value.length > 0 || submitCount > 0))
    }, [touched, error, value, submitCount])

    useEffect(() => {
      if (visibility !== null) {
        if (Array.isArray(visibility)) {
          if (visibilitylogic === 'or') {
            let isVisible = false
            for (let i = 0; i < visibility.length; i += 1) {
              const [field, val] = visibility[i].split('|')
              if (values?.[field]?.includes(val)) {
                isVisible = true
                break
              }
            }
            setFieldVisibility(isVisible)
          } else {
            let isVisible = true

            for (let i = 0; i < visibility.length; i += 1) {
              const [key, val] = visibility[i].split('|')
              if (values[key]?.toString() !== val) {
                isVisible = false
              }
            }
            setFieldVisibility(isVisible)
          }
        } else {
          const [field, val] = visibility.split('|')
          if (values?.[field]) {
            setFieldVisibility(values?.[field]?.includes(val))
          }
        }
      } else {
        setFieldVisibility(true)
      }
    }, [values])

    useEffect(() => {
      if (visibility !== null) {
        updateForm({ name, required: fieldVisibility ? props.required : false })
      }
    }, [fieldVisibility])

    return (
      <div className={className}>
        <Label
          label={label}
          htmlFor={name}
          visibility={hasLabel}
          isFocused={isFocused}
          isError={isError}
        />
        {span ? (
          <div
            className="form-span"
            dangerouslySetInnerHTML={{ __html: span }}
          />
        ) : null}
        <Error
          id={accessibleErrorName.current}
          message={error}
          visibility={isError}
        >
          <textarea
            ref={ref}
            id={name}
            className={clsx({
              'form-control': true,
              'form-disabled': disabled,
              invalid: isError,
            })}
            name={name}
            disabled={disabled}
            title={label}
            aria-describedby={accessibleErrorName.current}
            aria-invalid={!!error}
            value={value || ''}
            onBlur={handleBlur}
            onFocus={handleFocus}
            {...omit(
              props,
              'setFieldValue',
              'options',
              'optionsData',
              'visibility',
              'values',
              'map',
              'asyncdata',
              'dynamic',
              'required',
              'isRequired',
              'checkboxLabel',
            )}
            required={fieldVisibility ? props.required : false}
          />
        </Error>
      </div>
    )
  },
)

export default injectSheet(style)(Textarea)
