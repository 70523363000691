const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      zIndex: theme.zindex.modal,
      top: 0,
      left: 0,
      width: '100%',
      height: 'calc(var(--vh) * 100)',
      pointerEvents: 'none',
      overflow: 'hidden',
    },
    overlay: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: 'calc(var(--vh) * 100)',
      background: theme.getRgba(theme.colors[2], 0.4),
    },
    popup: {
      position: 'fixed',
      zIndex: 1,
      top: '50%',
      left: '50%',
      maxWidth: 800,
      width: '100%',
      padding: [0, '4vw'],
      transform: 'translate(-50%, -50%)',
    },
    container: {
      position: 'relative',
      zIndex: 1,
      background: theme.colors[1],
      borderRadius: 5,
      padding: 40,
    },
    close: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 1,
      top: 10,
      right: 10,
      width: 41,
      height: 41,
      borderRadius: '50%',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '& svg': {
        width: 12,
        height: 12,
        fill: theme.colors[1],
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        borderRadius: '50%',
        background: theme.colors[0],
        width: '100%',
        height: '100%',
        transition: `all 1s ${theme.easings['power3.out']}`,
      },
      '@media (hover:hover)': {
        '&:hover': {
          '&:before': {
            background: theme.colors[2],
          },
        },
      },
    },
    title: {
      textAlign: 'center',
      fontSize: 26,
      fontWeight: 400,
      marginBottom: 50,
      marginTop: 0,
    },
    content: {
      fontSize: 16,
    },
    form: {
    },
  }

  output[theme.mq.lg] = {
  }

  output[theme.mq.sm] = {
    root: {
      top: 0,
      left: 0,
      maxWidth: 'none',
      width: '100%',
      height: 'calc(var(--vh-fixed) * 100)',
      padding: 0,
      transform: 'translate(0, 0)',
      overflow: 'auto',
    },
    overlay: {
      height: 'calc(var(--vh-fixed) * 100)',
    },
    container: {
      borderRadius: 0,
      padding: 40,
    },
  }

  return output
}

export default style
