import clsx from 'clsx'

function Label({
  htmlFor,
  className,
  label,
  visibility,
  children,
  isError,
  type,
}) {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx({
        'form-label': true,
        visuallyHidden: !visibility,
        invalid: isError,
        [className]: className,
      })}
    >
      {type !== 'checkbox' ? label : ''}
      {children}
    </label>
  )
}

export default Label
