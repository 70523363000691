export const isEmptyObject = (obj) => {
  for (const prop in obj) { // eslint-disable-line
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }
  return true
}

export const clone = (obj) => ({ ...obj })

export const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object)
  const targetKey = clonedObj[key]
  delete clonedObj[key]
  clonedObj[newKey] = targetKey
  return clonedObj
}

export const createVars = (object, prefix) => {
  let newObj = object
  Object.keys(object).forEach((key) => {
    newObj = renameKey(newObj, key, `--${prefix}-${key}`)
  })
  return {
    ':root': {
      ...newObj,
    },
  }
}

export const omit = (obj, ...props) => {
  const result = { ...obj }
  props.forEach((prop) => {
    delete result[prop]
  })
  return result
}

export const cleanObject = (obj, propertiesToKeep) => {
  const result = {}

  for (const property of propertiesToKeep) { // eslint-disable-line
    const value = property?.split('.').reduce((o, key) => o?.[key], obj)
    if (value !== undefined) {
      let targetObj = result
      const keys = property.split('.')
      for (let i = 0; i < keys.length - 1; i += 1) {
        const key = keys[i]
        targetObj[key] = targetObj[key] || {}
        targetObj = targetObj[key]
      }
      targetObj[keys[keys.length - 1]] = value
    }
  }

  return result
}
