const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      padding: '46px var(--gutter)',
      width: '100%',
      boxSizing: 'border-box',
      zIndex: theme.zindex.header,
      pointerEvents: 'none',
      transition: 'transform 0.4s ease-out, padding .4s ease-out, height .4s ease-out',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: theme.colors[1],
        transform: 'scaleY(0)',
        transformOrigin: '0 0',
        transition: 'transform .4s ease-out 0s',
      },
    },
    preview: {
      position: 'relative',
      zIndex: 1,
      height: 38,
      display: 'flex',
      alignItems: 'center',
    },
    negative: {
      paddingTop: 20,
      paddingBottom: 20,
      transition: 'transform 0.4s ease-out, padding .4s ease-out, height .4s ease-out',
      '&:before': {
        transform: 'scaleY(1)',
        transition: 'transform .4s ease-out .9s',
      },
    },
    navIsOpen: {
      '&$negative': {
        transition: 'transform 0.4s ease-out, padding .4s ease-out, height .4s ease-out',
        '&:before': {
          transform: 'scaleY(0)',
          transition: 'transform .4s ease-out 0s',
        },
      },
      '& $btnProducts': {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity .3s ease-out',
      },
      '& $inputSearch': {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity .3s ease-out',
        '& input, & button': {
          pointerEvents: 'none',
        },
      },
    },
    navIsNotVisible: {
      transform: 'translateY(-100%)',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logo: {
      flex: '1 0 auto',
      display: 'inline-block',
      width: 'auto',
      textAlign: 'left',
      marginRight: 24,
      paddingTop: 5,
    },
    btnNav: {
      flex: '0 1 auto',
      textAlign: 'right',
    },
    btnProducts: {
      zIndex: 1,
      flex: '0 1 38px',
      marginRight: 24,
      pointerEvents: 'all',
      width: 38,
      height: 38,
      borderRadius: '50%',
      background: theme.getRgba(theme.colors[5], 0.5),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'opacity .3s ease-out 1s, background-color .3s ease-in-out',
      '&:hover': {
        background: theme.getRgba(theme.colors[5], 1),
      },
      '& svg': {
        width: 10,
        height: 10,
      },
    },
    backLink: {
      transform: 'rotate(90deg)',
      transformOrigin: 'center',
      cursor: 'pointer',
      position: 'relative',
      zIndex: 1,
      marginRight: 10,
      pointerEvents: 'all',
      overflow: 'hidden',
      display: 'inline-block',
      borderRadius: '50%',
      '& .btnEye': {
        border: 'none',
        background: theme.getRgba(theme.colors[5], 0.5),
        '& svg': {
          fill: theme.getRgba(theme.colors[2], 0.8),
        },
        '&:before, &:after': {
          display: 'none',
        },
      },
    },
    searchLink: {
      width: 38,
      height: 38,
      background: theme.getRgba(theme.colors[5], 0.5),
      marginRight: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      pointerEvents: 'all',
      position: 'relative',
      zIndex: 1,
      '& svg': {
        width: 14,
        height: 14,
      },
    },
    inputSearch: {
      flex: '0 1 190px',
      maxWidth: 190,
      marginRight: 24,
      transition: 'opacity .3s ease-out 1s',
      '& input': {
        fontSize: 14,
        background: theme.getRgba(theme.colors[7], 0.5),
        transition: 'background-color .3s ease-in-out',
        '&:focus': {
          background: theme.getRgba(theme.colors[7], 1),
        },
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      padding: '30px var(--gutter)',
    },
    negative: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    btnProducts: {
      display: 'none',
    },
    navIsOpen: {
      '&  $backLink, & $btnSearchLink': {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity .3s ease-in-out .1s',
      },
    },
    backLink: {
      transition: 'opacity .3s ease-in-out .8s',
      opacity: 1,
      pointerEvents: 'auto',
    },
    btnSearchLink: {
      transition: 'opacity .3s ease-in-out .8s',
      opacity: 1,
      pointerEvents: 'auto',
    },
  }

  /*------------------------------
  XS
  ------------------------------*/
  output[theme.mediaInverse.xs] = {
    inputSearch: {
      '& svg': {
        width: 12,
        height: 12,
      },
    },
  }

  return output
}

export default style
