import {
  useRef,
  useCallback,
  useState,
  forwardRef,
  useEffect,
} from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import ReactHtmlParser from 'react-html-parser'
import Error from '@/components/__form/Error'
import Label from '@/components/__form/Label'
import style from '../BaseForm/style'

const Checkbox = forwardRef(
  (
    {
      className,
      disabled,
      label,
      checkboxLabel,
      name,
      error,
      touched,
      hasLabel,
      submitCount,
      value,
      type,
      onBlur,
      onFocus,
      onChange,
      setFieldValue,
      visibility,
      visibilitylogic,
      values,
      updateForm,
      ...props
    },
    ref,
  ) => {
    const accessibleErrorName = useRef(`${name}-error`)
    const [isFocused, setFocused] = useState(false)
    const [isError, setError] = useState(false)
    const [fieldVisibility, setFieldVisibility] = useState(visibility === null)

    const handleBlur = (e) => {
      onBlur(e)
      setFocused(false)
    }

    const handleFocus = (e) => {
      onFocus(e)
      setFocused(true)
    }

    useEffect(() => {
      setError(touched && error && submitCount > 0)
    }, [touched, error, submitCount])

    useEffect(() => {
      if (visibility !== null) {
        if (Array.isArray(visibility)) {
          if (visibilitylogic === 'or') {
            let isVisible = false
            for (let i = 0; i < visibility.length; i += 1) {
              const [field, val] = visibility[i].split('|')
              if (values?.[field]?.includes(val)) {
                isVisible = true
                break
              }
            }
            setFieldVisibility(isVisible)
          } else {
            let isVisible = true

            for (let i = 0; i < visibility.length; i += 1) {
              const [key, val] = visibility[i].split('|')
              if (values[key]?.toString() !== val) {
                isVisible = false
              }
            }
            setFieldVisibility(isVisible)
          }
        } else {
          const [field, val] = visibility.split('|')
          if (values?.[field]) {
            setFieldVisibility(values?.[field]?.includes(val))
          }
        }
      } else {
        setFieldVisibility(true)
      }
    }, [values])

    const [transformedContent, setTransformedContent] = useState()

    const transformContent = useCallback(() => {
      const transform = (node, i) => { // eslint-disable-line
        // eslint-disable-line
        if (node.type === 'tag' && node.name === 'a') {
          return (
            <a key={i.toString()} href={node.attribs.href}>
              {node.children[0].data}
            </a>
          )
        }
      }
      setTransformedContent(ReactHtmlParser(checkboxLabel, { transform }))
    }, [checkboxLabel])

    useEffect(() => {
      transformContent()
    }, [checkboxLabel])

    useEffect(() => {
      if (visibility !== null) {
        updateForm({ name, required: fieldVisibility ? props.required : false })
      }
    }, [fieldVisibility])

    return (
      <div
        className={clsx({
          [className]: className,
        })}
      >
        <div
          className={clsx({
            'form-check-input': true,
            invalid: isError,
          })}
        >
          <Label
            label={checkboxLabel}
            visibility={hasLabel}
            isFocused={isFocused}
            isError={isError}
            type="checkbox"
          >
            <Error
              id={accessibleErrorName.current}
              message={error}
              visibility={isError}
              padding={true}
            >
              <input
                ref={ref}
                disabled={disabled}
                name={name}
                type="checkbox"
                title={checkboxLabel}
                tabIndex="0"
                className={clsx({
                  'form-disabled': disabled,
                })}
                checked={value || false}
                onChange={(e) => {
                  setFieldValue(name, e.target.checked)
                  setFieldValue(`${name}_1`, e.target.checked ? '1' : '0')
                }}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
              <span
                className={clsx({
                  error: isError,
                })}
              >
                {transformedContent}
              </span>
            </Error>
          </Label>
        </div>
      </div>
    )
  },
)

export default injectSheet(style)(Checkbox)
