import { useRef, useState, forwardRef, useEffect } from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import Select, { components } from 'react-select'
import { mergeRefs } from 'react-merge-refs'
import Error from '@/components/__form/Error'
import Label from '@/components/__form/Label'
import { omit } from '@/utils/object'
import style from './style'

function MenuList({ children, ...props }) {
  return (
    <div data-lenis-prevent>
      <components.MenuList {...props}>
        {children}
      </components.MenuList>
    </div>
  )
}

const MultipleSelect = forwardRef(({
  classes,
  className,
  disabled,
  label,
  name,
  error,
  touched,
  hasLabel,
  submitCount,
  value,
  type,
  onBlur,
  onFocus,
  onChange,
  setFieldValue,
  options = [],
  placeholder,
  multiple,
  visibility,
  visibilitylogic,
  values,
  span,
  isClearable = false,
  updateForm,
  ...props
}, ref) => {
  const $root = useRef()
  const $select = useRef()
  const [fieldVisibility, setFieldVisibility] = useState(visibility === null)

  const accessibleErrorName = useRef(`${name}-error`)
  const [isFocused, setFocused] = useState(false)
  const [isError, setError] = useState(false)

  const handleBlur = (e) => {
    $root.current.classList.remove('isFocus')
    onBlur(e)
    setFocused(false)
  }

  const handleFocus = (e) => {
    $root.current.classList.add('isFocus')
    onFocus(e)
    setFocused(true)
  }

  useEffect(() => {
    setError(error && (value.length > 0 || submitCount > 0))
  }, [error, value, submitCount])

  useEffect(() => {
    if (visibility !== null) {
      if (Array.isArray(visibility)) {
        if (visibilitylogic === 'or') {
          let isVisible = false
          for (let i = 0; i < visibility.length; i += 1) {
            const [field, val] = visibility[i].split('|')
            if (values?.[field]?.includes(val)) {
              isVisible = true
              break
            }
          }
          setFieldVisibility(isVisible)
        } else {
          let isVisible = true

          for (let i = 0; i < visibility.length; i += 1) {
            const [key, val] = visibility[i].split('|')
            if (values[key]?.toString() !== val) {
              isVisible = false
            }
          }
          setFieldVisibility(isVisible)
        }
      } else {
        const [field, val] = visibility.split('|')
        if (values?.[field]) {
          setFieldVisibility(values?.[field]?.includes(val))
        }
      }
    } else {
      setFieldVisibility(true)
    }
  }, [values])

  useEffect(() => {
    if (visibility !== null) {
      updateForm({ name, required: fieldVisibility ? props.required : false })
    }
  }, [fieldVisibility])

  return (
    <div
      ref={$root}
      className={
        clsx({
          [classes.root]: true,
          [className]: true,
          hidden: !fieldVisibility,
        })
      }
    >
      <Label
        label={label}
        visibility={hasLabel}
        isFocused={isFocused}
        isError={isError}
        type={type}
        htmlFor={name}
      />
      { span ? <div className="form-span" dangerouslySetInnerHTML={{ __html: span }} /> : null}
      <Error
        id={accessibleErrorName.current}
        message={error}
        visibility={isError}
      >
        <Select
          ref={mergeRefs([ref, $select])}
          data-lenis-prevent
          id={name}
          className={clsx({
            'form-select': true,
            invalid: isError,
          })}
          // menuIsOpen={true}
          menuPortalTarget={document.body}
          aria-label={placeholder}
          title={label}
          tabIndex="0"
          isMulti={multiple}
          onChange={onChange}
          spellCheck={false}
          isClearable={isClearable}
          isSearchable={false}
          onBlur={handleBlur}
          onFocus={handleFocus}
          defaultValue=""
          placeholder={placeholder}
          isDisabled={disabled}
          role="listbox"
          maxMenuHeight={200}
          classNamePrefix="react-select"
          components={{ MenuList }}
          {...(omit(props, 'setFieldValue', 'type', 'placeholder', 'values', 'asyncdata', 'dynamic', 'required'))}
          required={fieldVisibility ? props.required : false}
          options={options.map((option) => ({
            ...option,
            label: option.text,
          }))}
        />
      </Error>
    </div>
  )
})

export default injectSheet(style)(MultipleSelect)
