const style = (theme) => {
  return {
    root: {
      zIndex: 1,
      position: 'relative',
    },
    errorMessage: {
      margin: 0,
      padding: 0,
      fontSize: 8,
      color: theme.color.error,
      bottom: -15,
      left: 0,
      position: 'absolute',
      userSelect: 'none',
      '&:empty': {
        display: 'none',
      },
    },
  }
}

export default style
