const style = (theme) => ({
  root: {
    '& .form-select': {
      backgroundImage: 'none',
      padding: '0',
      border: 'none',
      userSelect: 'none',
      '&:hover': {},
    },
    '& [class$="-control"]': {
      borderColor: 'var(--color-neutral-10)',
      backgroundImage: 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3e%3cpath fill=\'none\' stroke=\'%23343a40\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'M2 5l6 6 6-6\'/%3e%3c/svg%3e")',
      backgroundSize: '16px 12px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 0.75rem center',
    },
    '& [class$="-IndicatorsContainer"]': {
      transform: 'scale(.9)',
    },
    '& [class$="-indicatorContainer"]': {
      transform: 'scale(.9)',
    },
    '& [class$="-IndicatorsContainer"] > :last-child': {
      opacity: 0,
    },
    '& [class$="-indicatorContainer"] > :last-child': {
      // opacity: 0,
    },
    '& [class$="-indicatorSeparator"] + [class$="-indicatorContainer"] > :last-child': {
      opacity: 0,
    },
    '& [class$="-MultiValueRemove"]': {
      '&:hover': {},
    },
    '& [class$="-Input"]': {
      '& input': {
        color: 'var(--color)',
      },
    },
    '& [class$="-singleValue"]': {
      color: 'var(--color)',
    },
    '& [class$="-placeholder"]': {
      color: 'var(--color-neutral-60)!important',
    },

    /*------------------------------
    Focus
    ------------------------------*/
    '&.isFocus': {
      zIndex: 2,
      '& [class$="-control"]': {
        borderColor: 'var(--color)',
        outline: 0,
        boxShadow: '0 0 0 2px #d3dbf1',
      },

      '& .invalid': {
        '& [class$="-control"]': {
          boxShadow: '0 0 0 2px rgb(220 53 69 / 25%)',
        },
      },
    },

    '& .invalid': {
      '& [class$="-control"]': {
        borderColor: theme.color.error,
        outline: 0,
      },
    },

  },
  noInteraction: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
})

export default style
