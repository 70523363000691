import { useEffect, useState, lazy } from 'react'
import { useLocation } from 'react-router-dom'

const Post = lazy(() => import(/* webpackChunkName: "post" */'@/pages/Post'))
const Project = lazy(() => import(/* webpackChunkName: "project" */'@/pages/Project'))
const NewProduct = lazy(() => import(/* webpackChunkName: "new_product" */'@/pages/NewProduct'))

function Preview({
  getImageUrl,
  getImageTitle,
  theme,
}) {
  const [currentPostType, setCurrentPostType] = useState('')
  const [currentId, setCurrentID] = useState('')
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const postType = searchParams.get('type')
    const postId = searchParams.get('pid')
    setCurrentPostType(postType || 'post')
    if (postId) setCurrentID(postId)
  }, [])

  return (
    <>
      {currentPostType === '_cty_project' ? (
        <Project getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="project" preview={currentId} />
      ) : null}
      {currentPostType === 'post' ? (
        <Post preview={currentId} />
      ) : null}
      {currentPostType === 'family-detail' ? (
        <NewProduct getImageUrl={getImageUrl} getImageTitle={getImageTitle} theme={theme} cpt="family-detail" preview={currentId} />
      ) : null}
    </>
  )
}

export default Preview
