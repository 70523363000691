/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useLayoutEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import injectSheet from 'react-jss'
import gsap from 'gsap'
import { mainAxios as axios } from '@/base/axios'
import usePrevious from '@/hooks/usePrevious'
import BaseForm from '@/components/__form/BaseForm'
import * as layerActions from '@/actions/layer'
import * as pageActions from '@/actions/page'
import useConfiguratorStore from '@/store/configurator'
import { API, GF_CONSUMER_KEY, GF_CONSUMER_SECRET } from '@/constants'
import { convertJsontoFormData } from '@/utils/form'
import style from './style'

function ModalForm({
  id,
  classes,
  save = false,
}) {
  const $root = useRef()
  const $popup = useRef()
  const [form, setForm] = useState([])
  const [currentProject, projectName, projectImage, resetProject] = useConfiguratorStore((state) => [state.currentProject, state.projectName, state.projectImage, state.resetProject])
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isModalOpen } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    isModalOpen: state.layer.layers.some((layer) => layer.id === `modal-form-${id}` && layer.isOpen),
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeLayer = useCallback(() => dispatch(layerActions.closeLayer({ id: `modal-form-${id}` })), [dispatch, id])
  const createLayerModel = useCallback(() => dispatch(layerActions.createLayerModel({ id: `modal-form-${id}` })), [dispatch, id])
  const fetchForm = useCallback((idx) => dispatch(pageActions.fetchForm(idx)), [dispatch])

  /*------------------------------
  Set Ready
  ------------------------------*/
  useEffect(() => {
    gsap.set($root.current, { autoAlpha: 0 })
    gsap.set($popup.current, { autoAlpha: 0, y: '10%' })
  }, [])

  useEffect(() => {
    async function fetchData() {
      createLayerModel()
      const data = await fetchForm(id)
      setForm(data)
    }
    if (id) fetchData()
  }, [id])

  /*------------------------------
  Manage Esc Key Down
  ------------------------------*/
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isModalOpen) closeLayer()
  }, [isModalOpen])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isModalOpen])

  /*------------------------------
  Toggle Interaction
  ------------------------------*/
  const toggleInteractionOnModal = useCallback((status) => {
    if (status && $root.current) $root.current.style.pointerEvents = status
  }, [])

  const openModal = useCallback(() => {
    gsap.timeline({ onStart: () => toggleInteractionOnModal('all') })
      .killTweensOf([$root.current, $popup.current])
      .to($root.current, {
        duration: 0.8,
        autoAlpha: 1,
        ease: 'power3.out',
      })
      .to($popup.current, {
        duration: 0.8,
        autoAlpha: 1,
        y: '0%',
        ease: 'power3.out',
      }, 0)
  }, [])

  const closeModal = useCallback(() => {
    gsap.timeline({ onStart: () => {
      toggleInteractionOnModal('none')
      resetProject()
    } })
      .killTweensOf([$root.current, $popup.current])
      .to($popup.current, {
        duration: 0.8,
        autoAlpha: 0,
        ease: 'power3.out',
      })
      .to($root.current, {
        duration: 0.8,
        autoAlpha: 0,
        ease: 'power3.out',
      }, 0)
  }, [])

  /*------------------------------
  Close Nav when isOpen changed
  ------------------------------*/
  const prevIsModalOpen = usePrevious(isModalOpen)
  useEffect(() => {
    if (prevIsModalOpen !== undefined && isModalOpen) openModal()
    if (prevIsModalOpen !== undefined && !isModalOpen) closeModal()
  }, [isModalOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (
      prevPathname !== undefined
      && prevPathname !== pathname
    ) {
      setTimeout(() => closeModal(), 500)
    }
  }, [pathname])

  async function handleSendForm(sendData) {
    const token = `${GF_CONSUMER_KEY}:${GF_CONSUMER_SECRET}`
    const encodedToken = btoa(token)
    const headers = { 'Content-Type': 'multipart/form-data', Authorization: `Basic ${encodedToken}` }
    const response = await axios.post(
      `${API.GF_GET}/${id}/submissions`,
      convertJsontoFormData(sendData),
      { headers },
    )
    return response
  }

  const handleSaveProject = async (email) => {
    if (!save) return null
    const headers = { 'Content-Type': 'application/json', Authorization: 'Basic Q29uZmlndXJhdG9yZTpuYUxpOXBqQTVmazlObFdnRWpiRzF3c2w=' }
    const response = await axios.post(
      API.CONFIGURATION,
      JSON.stringify({
        title: `Configurazione di ${email}: ${projectName}`,
        status: 'publish',
        content: currentProject.pproContent,
        acf: {
          product_name: projectName,
          product_image: projectImage,
        },
      }),
      { headers },
    )
    return response
  }

  const handleSuccess = () => {
    if (closeLayer) closeLayer()
  }

  return (
    <div
      className={classes.root}
      role="dialog"
      aria-modal="true"
      ref={$root}
    >
      <div
        className={classes.overlay}
        onClick={closeLayer}
      />
      <div
        className={classes.popup}
        ref={$popup}
      >
        <div className={classes.container}>
          <button
            className={classes.close}
            onClick={closeLayer}
            aria-label="close-modal"
          >
            <svg><use xlinkHref="#ico-close" /></svg>
          </button>
          {form?.title ? <h2 className={classes.title}>{form?.title}</h2> : null}
          <div className={classes.wrapper}>
            {form?.fields?.length > 0 ? (
              <BaseForm
                fields={form.fields}
                submitLabel={form?.button?.text}
                preSubmitCall={handleSaveProject}
                save={save}
                sendContactForm={handleSendForm} // eslint-disable-line
                onSuccessSend={handleSuccess}
                className={classes.form}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectSheet(style)(ModalForm)
