import { string, number, boolean, mixed, object, ref } from 'yup'

export const createYupSchema = (config, lang = 'en') => {
  const errors = require(`@/utils/form/errors/${lang}.json`) // eslint-disable-line
  const schema = config
    .filter(
      (c) => c.type !== 'space' && c.type !== 'title' && c.type !== 'multiple_dates',
    )
    .reduce((obj, item) => {
      let value = {}
      if (
        item.type === 'text'
        || item.type === 'date'
        || item.type === 'textarea'
        || item.type === 'select'
        || item.type === 'radio'
        || item.type === 'address'
        || item.type === 'website'
        || item.type === 'hidden'
      ) {
        value = string()
        if (item.isRequired) value = value.required(errors['required'])
      }
      if (item.type === 'email') {
        value = string().email(errors['email'])
        if (item.isRequired) value = value.required(errors['required'])
      }
      if (item.type === 'password') {
        value = string()
        if (item.isRequired) value = value.min(8, errors['password']).required(errors['required'])
      }
      if (item.type === 'confirm_password') {
        value = string()
        if (item.isRequired) {
          value = value
            .required(errors['required'])
            .oneOf([ref('user_pass'), null], errors['confirm_password'])
        }
      }
      if (item.type === 'tel' || item.type === 'phone') {
        value = number('').typeError(errors['tel'])
        if (item.isRequired) value = value.required(errors['required'])
      }
      if (item.type === 'checkbox' || item.type === 'consent') {
        value = boolean()
        if (item.isRequired) {
          value = value
            .oneOf([true], errors['checkbox'])
            .required(errors['required'])
        }
      }
      if (item.type === 'file' || item.type === 'fileupload') {
        value = mixed()
        if (item.isRequired) value = value.required(errors['required'])
      }

      return {
        ...obj,
        [`input_${item.id}`]: value,
      }
    }, {})

  return object().shape(schema)
}

export const createInitialValues = (fields, initialValues = []) => {
  const values = fields.reduce((obj, item) => {
    return {
      ...obj,
      [`input_${item.id}`]: item.type === 'checkbox' ? false : '',
    }
  }, {})
  return Object.assign(values, ...initialValues)
}

export const convertJsontoUrlencoded = (obj) => {
  const str = []
  for (const key in obj) { // eslint-disable-line no-restricted-syntax
    // eslint-disable-line no-restricted-syntax
    if (obj.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
      // eslint-disable-line no-prototype-builtins
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    }
  }
  return str.join('&')
}

export const convertJsontoFormData = (obj) => {
  const formData = new FormData()

  for (const key in obj) { // eslint-disable-line no-restricted-syntax
    // eslint-disable-line no-restricted-syntax
    if (obj.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
      // eslint-disable-line no-prototype-builtins
      formData.append(key, obj[key])
    }
  }
  return formData
}

export const parseReadableStreamToJson = async (error) => {
  const data = (await error.getReader().read()).value
  const str = String.fromCharCode.apply(String, data) // eslint-disable-line
  return JSON.parse(str)
}

export const getAriaRolesAndAttributes = (
  refs,
  type,
  checkIfNative,
  setAttribute = {},
) => {
  const attributes = {}
  // Check if the element is an input tag
  const isNativeInput = refs?.current?.tagName?.toLowerCase() === 'input'
  switch (type) {
    case 'button':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'button'
      }

      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-expanded'] = setAttribute['aria-expanded'] || 'false'
      attributes['aria-haspopup'] = setAttribute['aria-haspopup'] || 'false'
      attributes['aria-describedby'] = setAttribute['aria-describedby'] || ''
      break
    case 'checkbox':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'checkbox'
      }
      attributes['aria-checked'] = setAttribute['aria-checked'] || 'false'
      attributes['aria-invalid'] = setAttribute['aria-invalid'] || 'false'
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-describedby'] = setAttribute['aria-describedby'] || ''
      break
    case 'radio':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'checkbox'
      }
      attributes['aria-checked'] = setAttribute['aria-checked'] || 'false'
      attributes['aria-describedby'] = ''
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-describedby'] = setAttribute['aria-describedby'] || ''
      break
    case 'text':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'textbox'
      }
      attributes['aria-invalid'] = setAttribute['aria-invalid'] || 'false'
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-required'] = setAttribute['aria-required'] || 'false'
      attributes['aria-describedby'] = setAttribute['aria-describedby'] || ''
      break
    case 'email':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'textbox'
      }
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-multiline'] = setAttribute['aria-multiline'] || 'false'
      attributes['aria-required'] = setAttribute['aria-required'] || 'false'
      break
    case 'password':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'textbox'
      }
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-multiline'] = setAttribute['aria-multiline'] || 'false'
      attributes['aria-required'] = setAttribute['aria-required'] || 'false'
      break
    case 'number':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'spinbutton'
      }
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-required'] = setAttribute['aria-required'] || 'false'
      attributes['aria-valuemax'] = setAttribute['aria-valuemax'] || '100'
      attributes['aria-valuemin'] = setAttribute['aria-valuemin'] || '0'
      attributes['aria-valuenow'] = setAttribute['aria-valuenow'] || '0'
      break
    case 'range':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'slider'
      }
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-required'] = setAttribute['aria-required'] || 'false'
      attributes['aria-valuemax'] = setAttribute['aria-valuemax'] || '100'
      attributes['aria-valuemin'] = setAttribute['aria-valuemin'] || '0'
      attributes['aria-valuenow'] = setAttribute['aria-valuenow'] || '0'
      break
    case 'search':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'searchbox'
      }
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-required'] = setAttribute['aria-required'] || 'false'
      break
    case 'select':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'listbox'
      }
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-required'] = setAttribute['aria-required'] || 'false'
      attributes['aria-multiselectable'] = setAttribute['aria-multiselectable'] || 'false'
      break
    case 'textarea':
      if (!isNativeInput && checkIfNative) {
        attributes.role = ''
      } else {
        attributes.role = 'textbox'
      }
      attributes['aria-disabled'] = setAttribute['aria-disabled'] || 'false'
      attributes['aria-readonly'] = setAttribute['aria-readonly'] || 'false'
      attributes['aria-multiline'] = setAttribute['aria-multiline'] || 'false'
      attributes['aria-required'] = setAttribute['aria-required'] || 'false'
      break
    default:
      attributes.role = ''
  }

  // Merge the additional attributes with the attributes object
  return { ...attributes }
}
