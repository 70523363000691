/* eslint-disable react/no-array-index-key */
import { forwardRef, useRef, useState, useEffect } from 'react'
import injectSheet from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import { createInitialValues, createYupSchema } from '@/utils/form'
import { useFormik } from 'formik'
import clsx from 'clsx'
import FormField from '@/components/__form/FormField'
import Fieldset from '@/components/__form/Fieldset'
import { theme } from '@/styles/style'
import useConfiguratorStore from '@/store/configurator'
import style from './style'

const BaseForm = forwardRef(
  (
    {
      classes,
      className,
      fields = [],
      hiddenValues: hiddenValuesFromProps = [],
      initialValues: initialValuesFromProps,
      delayAfterReset = 20000,
      sendContactForm,
      onSuccessSend,
      preSubmitCall,
      save,
      submitLabel,
      submitCallback,
    },
    ref,
  ) => {
    const $root = useRef()
    const [YUPFields, setYUPFields] = useState(fields)
    const [hiddenValues, setHiddenValues] = useState([])
    const [validationSchema, setValidationSchema] = useState({})
    const [initialValues, setInitialValues] = useState({})
    const [statusMessage, setStatusMessage] = useState('')
    const currentProject = useConfiguratorStore((state) => state.currentProject)

    /*------------------------------
      Redux Connect
      ------------------------------*/
    const { currentLanguage } = useSelector((state) => ({
      currentLanguage: state.locale.currentLanguage,
    }), shallowEqual)

    const formik = useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      validateOnBlur: false,
      onSubmit: async (values, { setSubmitting, setStatus, resetForm }) => {
        let file
        if (fields.some((f) => f.type === 'fileupload')) {
          file = new File([currentProject?.otherData?.pdfBlob || currentProject?.otherData?.threeDBlob], currentProject?.otherData?.pdfFileName || currentProject?.otherData?.threeDFileName, {
            type: currentProject?.otherData?.pdfBlob?.type || currentProject?.otherData?.threeDBlob?.type,
            lastModified: Date.now(),
          })
        }
        let data
        if (file) {
          const fileField = { [`input_${fields.find((f) => f.type === 'fileupload').id}`]: file }
          data = { ...values, ...hiddenValues, ...fileField }
        } else {
          data = { ...values, ...hiddenValues }
        }

        if (submitCallback) submitCallback(data)

        const isHoneyCompiled = $root.current.querySelector('input[name="name-surname"]').value
          .length > 0

        if (isHoneyCompiled) {
          setStatusMessage('Suspicious request')
          setStatus('warning')

          setTimeout(() => {
            resetForm()
            setStatusMessage('')
            setStatus('')
          }, delayAfterReset)
        } else {
          // Esegui la chiamata preliminare se necessario
          if (save && preSubmitCall) {
            try {
              const emailField = fields?.find((f) => f.type === 'email')
              const preResponse = await preSubmitCall(values[`input_${emailField.id}`])
              if (preResponse && preResponse?.data?.id) {
                const linkField = { [`input_${fields.find((f) => f.adminLabel === 'link_configurazione').id}`]: `https://ciamweb.it/it/configurator/?project=${preResponse.data.id}` }
                data = { ...data, ...linkField }
              }
            } catch (error) {
              console.error('Errore nella chiamata preliminare:', error)
              setStatusMessage('Errore durante la preparazione del form. Si prega di riprovare')
              setStatus('error')
              setSubmitting(false)
              return
            }
          }

          const filteredData = Object.keys(data)
            .filter((key) => data[key] !== '')
            .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})

          const result = sendContactForm(filteredData)

          result
            .then((response) => {
              setStatusMessage(response?.data?.confirmation_message)
              setStatus(response?.data?.is_valid ? 'success' : 'error')
              return response
            })
            .catch((response) => {
              setStatusMessage(
                "Errore durante l'invio del form. Si prega di riprovare",
              )
              setStatus(response?.data?.is_valid ? 'success' : 'error')
            })
            .then((response) => {
              setTimeout(() => {
                if (onSuccessSend && response?.data?.is_valid) onSuccessSend(response, data)
                resetForm()
                setStatusMessage('')
                setStatus(undefined)
                setSubmitting(false)
              }, delayAfterReset)
            })
        }
      },
    })

    useEffect(() => {
      if (fields.length > 0) {
        setHiddenValues(
          ...hiddenValuesFromProps,
          ...fields
            .filter((f) => f.type === 'hidden')
            .map((f) => ({ [f.name]: f.value })),
        )
      } else {
        setHiddenValues(hiddenValuesFromProps)
      }
    }, [])

    useEffect(() => {
      if (fields.length > 0 && currentLanguage) {
        setValidationSchema(createYupSchema(fields, currentLanguage))

        setInitialValues({
          ...createInitialValues(fields),
          ...initialValuesFromProps,
        })
      }
    }, [currentLanguage, initialValuesFromProps, fields])

    useEffect(() => {
      if (YUPFields.length > 0 && currentLanguage) setValidationSchema(createYupSchema(YUPFields, currentLanguage))
    }, [YUPFields, currentLanguage])

    const updateForm = ({ name, required }) => {
      setYUPFields((prev) => prev.map((item) => {
        if (item[`input_${item.id}`] === name) {
          return { ...item, required }
        }
        return item
      }))
    }

    return fields.length > 0 ? (
      <div className={className} ref={ref}>
        <form
          ref={$root}
          className={clsx({
            [classes.formRoot]: true,
            [classes.formDisabled]: statusMessage !== '',
          })}
          onSubmit={formik.handleSubmit}
          noValidate
          autoComplete="off"
        >
          <Fieldset>
            <label htmlFor="name-surname" />
            <input
              autoComplete="off"
              type="text"
              id="name-surname"
              name="name-surname"
              placeholder="Your name and Surname"
            />
            <div className={classes.grid}>
              {fields
                .filter((f) => f.visibility !== 'hidden')
                .map((field) => (
                  <FormField
                    key={field.id}
                    field={field}
                    formik={formik}
                    updateForm={updateForm}
                  />
                ))}
            </div>
          </Fieldset>
          <div
            className={clsx(
              classes.submitContainer,
              'xl:px-[calc(var(--gutter)*6)]',
            )}
          >
            <div>
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className={classes.submit}
              >
                {submitLabel}
              </button>

              <div
                className={clsx({
                  formResult: true,
                  [classes.result]: true,
                  [classes.visibility]: statusMessage !== '',
                })}
                style={{ '--background-color': theme.color[formik.status] }}
              >
                <p dangerouslySetInnerHTML={{ __html: statusMessage }} />
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : null
  },
)

export default injectSheet(style)(BaseForm)
