import { form } from '@/styles/form'

const global = (theme) => {
  const output = {
    '@global': {
      ':root': {
        '--plyr-color-main': theme.colors[0], // colore primario
        '--plyr-video-control-color': '#FFFFFF', // colore dei controlli
        '--plyr-video-control-color-hover': '#FFFFFF', // colore hover dei controlli
        '--plyr-video-control-background-hover': theme.getRgba(theme.colors[0], 0.8), // background hover dei controlli
        '--plyr-audio-control-color': '#FFFFFF',
        '--plyr-audio-control-color-hover': '#FFFFFF',
        '--plyr-audio-control-background-hover': theme.getRgba(theme.colors[0], 0.8),
        '--plyr-range-fill-background': theme.colors[0], // colore della barra di progresso
      },
      'html.lenis': {
        height: 'auto',
      },
      '.lenis.lenis-smooth': {
        scrollBehavior: 'auto',
      },
      '.lenis.lenis-smooth [data-lenis-prevent]': {
        overscrollBehavior: 'contain',
      },
      '.lenis.lenis-stopped': {
        overflow: 'hidden',
      },
      '.lenis.lenis-freeze': {
        overflow: 'hidden',
        height: '100vh',
        '& body': {
          overflow: 'hidden',
          height: '100vh',
        },
      },
      '.lenis.lenis-scrolling iframe': {
        pointerEvents: 'none',
      },
      '.visuallyHidden': {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: 1,
      },
      '.wp-block-image': {
        '& img': {
          height: 'auto',
        },
      },
      html: {
        '&:not(.dev)': {
          scrollbarWidth: 'none !important',
          '& body': {
            msOverflowStyle: 'none',
          },
          '&::-webkit-scrollbar': {
            width: '0 !important',
            height: '0 !important',
          },
        },
      },
      body: {
        fontFamily: theme.fonts[1],
        fontSize: 16,
        lineHeight: 1.2,
        color: theme.color[2],
        background: theme.color.background,
        overflowX: 'hidden',
        '--gutter': '60px',
        [theme.mediaInverse.md]: {
          '--gutter': '20px',
        },
      },
      '& [class^="scrollbar-root"]': {
        position: 'relative',
        zIndex: 7,
      },
      '& [class^="subheader-root"]': {
        position: 'relative',
        zIndex: 7,
      },
      '& [class^="nav-root"]': {
        position: 'relative',
        zIndex: 8,
      },
      '& [class^="header-root"]': {
        position: 'relative',
        zIndex: 9,
      },
      '& [class^="configurator-root"]': {
        position: 'relative',
        zIndex: 10,
      },
      '& [class^="modal-form-root"]': {
        position: 'relative',
        zIndex: 11,
      },
      '& .wp-block-gallery': {
        display: 'none !important',
      },
      '.wrapper': {
        position: 'relative',
        zIndex: 1,
      },
      '.route-container': {
        minHeight: '100vh',
      },
      img: {
        verticalAlign: 'middle',
        maxWidth: '100%',
      },
      '.container': {
        ...theme.container,
      },
      '.grid': {
        ...theme.grid,
      },
      '.col1': {
        ...theme.getCol(1),
      },
      '.col2': {
        ...theme.getCol(2),
      },
      '.col3': {
        ...theme.getCol(3),
      },
      '.col4': {
        ...theme.getCol(4),
      },
      '.col5': {
        ...theme.getCol(5),
      },
      '.col6': {
        ...theme.getCol(6),
      },
      '.col7': {
        ...theme.getCol(7),
      },
      '.col8': {
        ...theme.getCol(8),
      },
      '.offset1': {
        '--offset': 1,
      },
      '.offset2': {
        '--offset': 2,
      },
      '.offset3': {
        '--offset': 3,
      },
      '.offset4': {
        '--offset': 4,
      },
      '.offset5': {
        '--offset': 5,
      },
      '.offset6': {
        '--offset': 6,
      },
      '.offset7': {
        '--offset': 7,
      },
      '.offset8': {
        '--offset': 8,
      },
      'h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5': {
        color: theme.colors[4],
        fontFamily: theme.fonts[0],
        fontWeight: 300,
        lineHeight: 0.95,
        '& strong': {
          fontWeight: 300,
          color: theme.colors[0],
        },
      },
      'h1, .h1': {
        color: theme.colors[0],
        fontSize: 80,
        lineHeight: 1.1,
        textIndent: '-0.1em',
        [theme.mediaInverse.sm]: {
          fontSize: 36,
        },
      },
      'h2, .h2': {
        fontSize: 60,
        lineHeight: 1.1,
        [theme.mediaInverse.sm]: {
          fontSize: 32,
        },
      },
      'h3, .h3': {
        fontSize: 40,
        lineHeight: 1.2,
        [theme.mediaInverse.sm]: {
          fontSize: 28,
        },
      },
      'h4, .h4': {
        fontSize: 36,
        lineHeight: 1.2,
        [theme.mediaInverse.sm]: {
          fontSize: 25,
        },
      },
      'h5, .h5': {
        fontSize: 32,
        lineHeight: 1.2,
        [theme.mediaInverse.sm]: {
          fontSize: 23,
        },
      },
      'h6, .h6': {
        fontSize: 21,
        lineHeight: 1.2,
        [theme.mediaInverse.sm]: {
          fontSize: 21,
        },
      },
      '.pretitle': {
        fontFamily: theme.fonts[0],
        fontWeight: 300,
        fontSize: 18,
        lineHeight: 1.2,
        letterSpacing: 1.8,
        textTransform: 'uppercase',
        [theme.mediaInverse.sm]: {
          fontSize: 16,
        },
      },
      '.small': {
        fontSize: 14,
      },
      '.label': {
        fontFamily: theme.fonts[0],
        fontWeight: 400,
        fontSize: 13,
        letterSpacing: 1.3,
        textTransform: 'uppercase',
        [theme.mediaInverse.sm]: {
          fontSize: 12,
        },
      },
      '.pretit': {
        color: theme.colors[4],
        fontSize: 9,
        marginBottom: 30,
        textTransform: 'uppercase',
        letterSpacing: '0.34em',
      },
      '.title__line': {
        width: 52,
        height: 1,
        display: 'inline-block',
        background: theme.colors[0],
        verticalAlign: 'baseline',
        marginLeft: 10,
      },
      '.title__line--pre': {
        width: 52,
        height: 1,
        display: 'inline-block',
        background: theme.getRgba(theme.colors[2], 0.06),
        verticalAlign: 'baseline',
        marginRight: 10,
      },
      '.tag': {
        fontFamily: theme.fonts[0],
        display: 'inline-block',
        color: theme.colors[4],
        background: theme.colors[7],
        borderRadius: 16,
        padding: '5px 33px',
        textDecoration: 'none',
        verticalAlign: 'middle',
        margin: '0 20px 23px 0',
        transition: 'background .12s ease-in-out, color .12s ease-in-out',
        '&:hover': {
          background: theme.colors[4],
          color: theme.colors[1],
        },
        '&.no-link': {
          cursor: 'default',
          '&:hover': {
            color: theme.colors[4],
            background: theme.colors[7],
          },
        },
      },
      '.btn': {
        fontFamily: theme.fonts[0],
        fontWeight: 300,
        fontSize: 13,
        letterSpacing: 1.3,
        textTransform: 'uppercase',
        [theme.mediaInverse.sm]: {
          fontSize: 12,
        },
      },
      '& .btn-plus': {
        '&:before': {
          content: '"+ "',
        },
      },
      '.btnEye': {
        cursor: 'pointer',
        borderRadius: '50%',
        width: 38,
        height: 38,
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        border: '1px solid #000',
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          zIndex: -2,
          top: '-1%',
          left: '-1%',
          width: '102%',
          height: '102%',
          background: '#000',
        },
        '&:before': {
          zIndex: -1,
          background: '#fff',
          transform: 'scaleY(0)',
          transformOrigin: 'bottom',
          transition: 'transform .2s ease-in-out',
        },
        '& svg': {
          fill: theme.colors[1],
          width: 18,
          height: 18,
          transition: 'fill .2s ease-in-out',
        },
        '&:hover': {
          '&:before': {
            transform: 'scaleY(1)',
            transformOrigin: 'top',
          },
          '& svg': {
            fill: '#000',
          },
        },
      },
      '.download-link': {
        position: 'relative',
        zIndex: 1,
        opacity: 0.45,
        textDecoration: 'none',
        color: theme.colors[4],
        transition: 'color .12s ease-in-out',
        cursor: 'pointer',
        display: 'table',
        margin: '16px 0',
        paddingLeft: 44,
        '& .ico': {
          background: theme.colors[5],
          position: 'absolute',
          zIndex: 1,
          left: 0,
          top: -4,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 32,
          height: 32,
          verticalAlign: 'middle',
          transition: 'background .12s ease-in-out',
          '& svg': {
            height: 12.5,
            width: 8,
            fill: theme.colors[4],
            transition: 'fill .12s ease-in-out',
          },
        },
        '&:hover': {
          opacity: 1,
          '& .ico': {
            background: theme.colors[4],
            '& svg': {
              fill: theme.colors[1],
            },
          },
        },
      },
      '.sizing': {
        position: 'relative',
        width: '100%',
        height: 0,
        '& > div, & .container': {
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
          height: '100%',
        },
      },
      ...form,
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    '@global': {
      'h1, .h1': {
        fontSize: 50,
      },
      '.title__line': {
        width: 30,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    '@global': {
      'h1, .h1': {
        textIndent: 0,
        fontSize: 40,
      },
      '.pretit': {
        marginBottom: 15,
      },
    },
  }

  /*------------------------------
  XS
  ------------------------------*/
  output[theme.mediaInverse.xs] = {
    '@global': {
      '.title__line': {
        width: 30,
      },
    },
  }

  return output
}

export default global
