import { useRef, useState, forwardRef, useEffect } from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import Error from '@/components/__form/Error'
import Label from '@/components/__form/Label'
import style from '../BaseForm/style'

const Input = forwardRef(({
  className,
  disabled,
  label,
  name,
  visibility,
  visibilitylogic,
  error,
  touched,
  hasLabel,
  submitCount,
  value,
  type,
  onBlur,
  onFocus,
  onChange,
  setFieldValue,
  values,
  span,
  updateForm,
  ...props
}, ref) => {
  const accessibleErrorName = useRef(`${name}-error`)
  const [isFocused, setFocused] = useState(false)
  const [isError, setError] = useState(false)
  const [fieldVisibility, setFieldVisibility] = useState(visibility === null)

  const handleBlur = (e) => {
    onBlur(e)
    setFocused(false)
  }

  const handleFocus = (e) => {
    onFocus(e)
    setFocused(true)
  }

  useEffect(() => {
    setError(touched && error && (value.length > 0 || submitCount > 0))
  }, [touched, error, value, submitCount])

  useEffect(() => {
    if (visibility !== null) {
      if (Array.isArray(visibility)) {
        if (visibilitylogic === 'or') {
          let isVisible = false
          for (let i = 0; i < visibility.length; i += 1) {
            const [field, val] = visibility[i].split('|')
            if (values?.[field]?.includes(val)) {
              isVisible = true
              break
            }
          }
          setFieldVisibility(isVisible)
        } else {
          let isVisible = true

          for (let i = 0; i < visibility.length; i += 1) {
            const [key, val] = visibility[i].split('|')
            if (values[key]?.toString() !== val) {
              isVisible = false
            }
          }
          setFieldVisibility(isVisible)
        }
      } else {
        const [field, val] = visibility.split('|')
        if (values?.[field]) {
          setFieldVisibility(values?.[field]?.includes(val))
        }
      }
    } else {
      setFieldVisibility(true)
    }
  }, [values])

  useEffect(() => {
    if (visibility !== null) {
      updateForm({ name, required: fieldVisibility ? props.required : false })
    }
  }, [fieldVisibility])

  return (
    <div
      className={clsx({
        [className]: className,
      })}
    >
      <Label
        label={label}
        visibility={hasLabel}
        isFocused={isFocused}
        isError={isError}
        type={type}
        htmlFor={name}
      />
      { span ? <div className="form-span" dangerouslySetInnerHTML={{ __html: span }} /> : null}
      <Error
        id={accessibleErrorName.current}
        message={error}
        visibility={isError}
      >
        <input
          ref={ref}
          disabled={disabled}
          id={name}
          name={name}
          type={type}
          className={clsx({
            'form-control': true,
            'form-disabled': disabled,
            invalid: isError,
          })}
          title={label}
          tabIndex="0"
          value={value || ''}
          onChange={onChange}
          spellCheck="false"
          onBlur={handleBlur}
          onFocus={handleFocus}
          required={fieldVisibility ? props.required : false}
        />
      </Error>
    </div>
  )
})

export default injectSheet(style)(Input)
